import axios from "axios";
import { AuthHeader, url } from "../../utils/_exports";
import { getAllUsers } from "./user";

const apiURL = "notifications";

// API to get current user notification status
export const getUserExists = (email: string, notification_type: any) => {
    return new Promise((resolve, reject) => {
        // Construct the `requestURL` 
        const requestURL = `${url}/${apiURL}`;
        console.log(notification_type, email)
        axios
            // Send a GET request to the server with the constructed `requestURL`
            .get(requestURL, {
                params: {
                    filter: `{"notification_type":"${notification_type}","receipients.user_email":"${email}"}`
                },
                // Spread the `AuthHeader` to include authorization for the request
                ...AuthHeader,
            })
            .then((response: any) => {
                if (response.data && response.data.length > 0) {
                    // Find the specific notification with the matching notification_type and email
                    const notification = response.data.find(
                        (notif: any) => notif.notification_type === notification_type
                    );

                    if (notification) {
                        // Find the recipient with the matching email in the notification
                        const recipient = notification.receipients.find(
                            (receipient: any) => receipient.user_email === email
                        );

                        if (recipient) {
                            resolve(recipient);
                        } else {
                            // No matching user email found in database
                            console.log("User with matching email not found.");
        
                            resolve(false);
                        }
                    } else {
                        // No matching `notification_type` found in database
                        console.log("No notification with the matching type found.");
                        resolve(false);
                    }
                } else {
                    resolve(false); // No matching notifications found
                    console.log("No notifications found for the given notification type.", notification_type);
                }
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}

// API to update newly found users into the notification collection
export const updateUserExists = (email: string, notification_type: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Step 1: Use getUserExists to check if the user already exists in the notification
            const userExists = await getUserExists(email, notification_type);

            if (userExists) {
                console.log("User already exists in the notification recipients.");
                resolve(true); // Return true if the user already exists
                return;
            }

            // Step 2: Use getNotification to fetch the notification based on the notification_type
            const notification = await getNotification(notification_type);

            if (notification) {
                const requestURL = `${url}/${apiURL}`;

                axios
                    .put(requestURL, {
                        filter: {
                            notification_type: notification_type,
                        },
                        data: {
                            $push: {
                                "receipients": {
                                    user_email: email,
                                    notified: false, // Set 'notified' to false for the new user
                                    read: false,     // Set 'read' to false for the new user
                                },
                            },
                        },
                        options: {
                            upsert: false, // Prevent creation of a new notification if none exists
                            new: true, // Return the updated document
                        },
                    },
                        { ...AuthHeader } // Include authorization headers
                    )
                    .then((response: any) => {
                        console.log("New user added successfully: ", response.data);
                        resolve(response.data);
                    })
                    .catch((error: any) => {
                        console.error("Error updating the specified notification type: ", error);
                        reject(error);
                    });
            } else {
                console.log("Notification with the specified type not found.");
                resolve(false); // Return false if no notification is found
            }
        } catch (error) {
            console.error("Error updating the notification users: ", error);
            reject(error);
        }
    });
};

export const getNotifications = () => {
    return new Promise((resolve, reject) => {
        const requestURL = `${url}/${apiURL}`;
        // console.log("Fetching all notifications from URL: ", requestURL);
        // console.log("AuthHeader", AuthHeader);
        axios
            .get(requestURL, {
                ...AuthHeader,
            })

            .then((response: any) => {
                console.log("Notifications fetched successfully: ", response.data);
                resolve(response.data);
            })
            .catch((error: any) => {
                // Reject if there's an error
                console.error("Error fetching notifications: ", error);
                reject(error);
            });
    });
};

export const getNotification = (notification_type: any) => {
    return new Promise((resolve, reject) => {
        const requestURL = `${url}/${apiURL}`;

        axios
            .get(requestURL, { ...AuthHeader })
            .then((response: any) => {
                // Filter to find the notification matching the specified type
                const notification = response.data.find(
                    (notif: any) => notif.notification_type === notification_type
                );

                if (notification) {
                    console.log("Notification fetched successfully: ", notification);
                    resolve(notification);
                } else {
                    console.log("No notification found for the specified type.");
                    resolve(false); // Return null if no matching notification is found
                }
            })
            .catch((error: any) => {
                console.error("Error fetching the notification: ", error);
                reject(error);
            });
    });
};

// API to post new notification to users throughout the system
export const postNotificationToUsers = (message: any, notification_type: any) => {
    return new Promise((resolve, reject) => {
        const requestURL = `${url}/${apiURL}`;

        // Fetch all users in the `users` collection
        getAllUsers()
        .then((users: any) => {
            // Extract all user emails from the user collection
            const userCollectionEmails = users.map((user: any) => user.email);
            console.log("User collection emails: ", userCollectionEmails);

            // Construct the request body for a new notification
            const requestBody = {
                message: message,
                notification_type: notification_type,
                created_on: new Date(),
                receipients: userCollectionEmails.map((email: string) => ({
                    user_email: email,
                    notified: false,
                    read: false,
                })),
            };

            // Send the POST request to create the notification
            axios
                .post(requestURL, requestBody, {
                    ...AuthHeader,
                })
                .then((response) => {
                    console.log("Notification created successfully: ", response);
                    resolve(response);
                })
                                                     .catch((error) => {
                    console.error("Error creating notification: ", error);
                    reject(error);
                });
        })
        .catch((error: any) => {
            console.error("Error fetching users: ", error);
            reject(error);
        });
    })
}

// API to get current user notification status
export const getUserNotificationStatus = (email: string, notification_type: any) => {
    return new Promise((resolve, reject) => {
        // Construct the `requestURL` 
        const requestURL = `${url}/${apiURL}`;
        axios
            // Send a GET request to the server with the constructed `requestURL`
            .get(requestURL, {
                params: {
                    filter: `{"notification_type":"${notification_type}","receipients.user_email":"${email}"}`
                },
                // Spread the `AuthHeader` to include authorization for the request
                ...AuthHeader,
            })
            .then((response: any) => {
                if (response.data && response.data.length > 0) {
                    // Find the specific notification with the matching notification_type and email
                    const notification = response.data.find(
                        (notif: any) => notif.notification_type === notification_type
                    );

                    if (notification) {
                        // Find the recipient with the matching email in the notification
                        const recipient = notification.receipients.find(
                            (receipient: any) => receipient.user_email === email
                        );

                        if (recipient) {
                            resolve(recipient.notified);
                            console.log("User Notification Status:", recipient.notified);
                        } else {
                            // No matching user email found in database
                            console.log("User with matching email not found.");
                            resolve(false);
                        }
                    } else {
                        // No matching `notification_type` found in database
                        console.log("No notification with the matching type found.");
                        resolve(false);
                    }
                } else {
                    resolve(false);
                    console.log("No notifications found for the given notification type.");
                }
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};

export const updateNotificationStatus = (email: string, notification_type: any) => {
    return new Promise((resolve, reject) => {
        const requestURL = `${url}/${apiURL}`;

        axios
            .put(requestURL, {
                filter: {
                    // notification_type: notification_type, 
                    "receipients.user_email": email
                },
                data: {
                    $set: {
                        "receipients.$[recipient].notified": true, // Update the notified status
                    },
                },
                options: {
                    arrayFilters: [{ "recipient.user_email": email }], // Apply to the specific recipient in the array
                    upsert: false, // Prevent creation of a new notification if none exists
                    new: true, // Return the updated document
                },
            },
                AuthHeader // Include authorization headers
            )
            .then((response: any) => {
                if (response.data) {
                    console.log(response.data);
                    // Find the updated recipient to log the 'notified' status
                    const notification = response.data.find(
                        (notif: any) => notif.notification_type === notification_type
                    );

                    if (notification) {
                        const receipient = notification.receipients.find(
                            (receipient: any) => receipient.user_email === email
                        );

                        if (receipient) {
                            resolve(receipient.notified);
                            console.log("User Notification Status:", receipient.notified);
                        } else {
                            resolve(false); // No matching email found in the notification
                            console.log("User with matching email not found.");
                        }
                    } else {
                        resolve(false); // No matching notification_type found
                        console.log("No notification with the matching type found.");
                    }
                } else {
                    console.log("No matching document found for the update.");
                    resolve(false);
                }
            })
            .catch((error: any) => {
                console.error("Error updating notification status: ", error);
                reject(error);
            });
    });
};

export const updateAllNotificationStatuses = (notification_type: any) => {
    return new Promise((resolve, reject) => {
        const requestURL = `${url}/${apiURL}`;

        axios
            .put(requestURL, {
                filter: {
                    notification_type: notification_type, // Filter by notification type
                },
                data: {
                    $set: {
                        "receipients.$[].notified": true, // Update the 'notified' status for all recipients
                    },
                },
                options: {
                    arrayFilters: [], // No filter needed, update all recipients in the array
                    upsert: false, // Prevent creation of a new notification if none exists
                    new: true, // Return the updated document
                },
            },
                AuthHeader // Include authorization headers
            )
            .then((response: any) => {
                if (response.data) {
                    console.log(response.data);
                    // Find the updated notification to log the 'notified' status of all recipients
                    const notification = response.data.find(
                        (notif: any) => notif.notification_type === notification_type
                    );

                    if (notification) {
                        const notifiedStatuses = notification.receipients.map(
                            (recipient: any) => recipient.notified
                        );
                        console.log("All Notification Statuses:", notifiedStatuses);
                        resolve(notifiedStatuses); // Return the updated statuses
                    } else {
                        resolve([]); // No matching notification_type found
                        console.log("No notification with the matching type found.");
                    }
                } else {
                    console.log("No matching document found for the update.");
                    resolve([]);
                }
            })
            .catch((error: any) => {
                console.error("Error updating notification status: ", error);
                reject(error);
            });
    });
};

// API to get read status of a notification for a user
export const getReadStatus = (email: string, notification_type: any) => {
    return new Promise((resolve, reject) => {
        // Construct the `requestURL`
        const requestURL = `${url}/${apiURL}`;
        
        axios
            // Send a GET request to the server with the constructed `requestURL`
            .get(requestURL, {
                params: {
                    filter: `{"notification_type":"${notification_type}","receipients.user_email":"${email}"}`,
                },
                // Spread the `AuthHeader` to include authorization for the request
                ...AuthHeader,
            })
            .then((response: any) => {
                if (response.data && response.data.length > 0) {
                    // Find the specific notification with the matching notification_type
                    const notification = response.data.find(
                        (notif: any) => notif.notification_type === notification_type
                    );

                    if (notification) {
                        // Find the recipient with the matching email in the notification
                        const recipient = notification.receipients.find(
                            (receipient: any) => receipient.user_email === email
                        );

                        if (recipient) {
                            resolve(recipient.read); // Return the read status (default to false if not set)
                            console.log("User Read Status:", recipient.read);
                        } else {
                            // No matching user email found in the database
                            console.log("User with matching email not found.");
                            resolve(false); // Default to unread if recipient not found
                        }
                    } else {
                        // No matching `notification_type` found in the database
                        console.log("No notification with the matching type found.");
                        resolve(false); // Default to unread if no notification type found
                    }
                } else {
                    resolve(false); // No matching notifications found
                    console.log("No notifications found for the given notification type.");
                }
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};

export const getReadStatuses = (email: string) => {
    return new Promise((resolve, reject) => {
        // Construct the `requestURL`
        const requestURL = `${url}/${apiURL}`;

        axios
            // Send a GET request to fetch all notifications for the user
            .get(requestURL, {
                params: {
                    filter: `{"receipients.user_email":"${email}"}`,
                },
                ...AuthHeader,
            })
            .then((response: any) => {
                if (response.data && response.data.length > 0) {
                    // Map through notifications and extract read status for the user
                    const statuses = response.data.map((notification: any) => {
                        const recipient = notification.receipients.find(
                            (receipient: any) => receipient.user_email === email
                        );

                        return {
                            notification_type: notification.notification_type,
                            read: recipient ? recipient.read : false, // Default to false if recipient not found
                        };
                    });

                    resolve(statuses);
                } else {
                    resolve(false);
                    console.log("No notifications found for the given user.");
                }
            })
            .catch((error: any) => {
                reject(error);
                console.error("Error fetching read statuses:", error);
            });
    });
};

export const updateReadStatus = (email: string, notification_type: any) => {
    return new Promise((resolve, reject) => {
        const requestURL = `${url}/${apiURL}`;

        axios
            .put(requestURL, {
                filter: {
                    "receipients.user_email": email
                },
                data: {
                    $set: {
                        "receipients.$[recipient].read": true,
                    },
                },
                options: {
                    arrayFilters: [{ "recipient.user_email": email }], // Apply to the specific recipient in the array
                    upsert: false, // Prevent creation of a new notification if none exists
                    new: true, // Return the updated document
                },
            },
                AuthHeader // Include authorization headers
            )
            .then((response: any) => {
                if (response.data) {
                    console.log(response.data);
                    // Find `notification_type` in the receipient array object that matches the `notification_type` parameter 
                    const notification = response.data.find(
                        (notif: any) => notif.notification_type === notification_type
                    );

                    // If `notification` exists
                    if (notification) {
                        const receipient = notification.receipients.find(
                            (receipient: any) => receipient.user_email === email
                        );

                        if (receipient) {
                            resolve(receipient.read);
                            console.log("User Read Status:", receipient.read);
                        } else {
                            resolve(false);
                            console.log("User with matching email not found.");
                        }
                    } else {
                        resolve(false);
                        console.log("No notification with the matching type found.");
                    }
                } else {
                    console.log("No matching document found for the update.");
                    resolve(false);
                }
            })
            .catch((error: any) => {
                console.error("Error updating notification status: ", error);
                reject(error);
            });
    });
};

export const updateAllReadStatuses = (notification_type: any) => {
    return new Promise((resolve, reject) => {
        const requestURL = `${url}/${apiURL}`;

        axios
            .put(requestURL, {
                filter: {
                    notification_type: notification_type, // Filter by notification type
                },
                data: {
                    $set: {
                        "receipients.$[].read": true, // Update the 'notified' status for all recipients
                    },
                },
                options: {
                    arrayFilters: [], // No filter needed, update all recipients in the array
                    upsert: false, // Prevent creation of a new notification if none exists
                    new: true, // Return the updated document
                },
            },
                AuthHeader // Include authorization headers
            )
            .then((response: any) => {
                if (response.data) {
                    console.log(response.data);

                    const notification = response.data.find(
                        (notif: any) => notif.notification_type === notification_type
                    );

                    if (notification) {
                        const readStatuses = notification.receipients.map(
                            (recipient: any) => recipient.read
                        );
                        console.log("All Read Statuses:", readStatuses);
                        resolve(readStatuses);
                    } else {
                        resolve([]);
                        console.log("No notification with the matching type found.");
                    }
                } else {
                    console.log("No matching document found for the update.");
                    resolve([]);
                }
            })
            .catch((error: any) => {
                console.error("Error updating notification status: ", error);
                reject(error);
            });
    });
};