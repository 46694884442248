import { SearchOutlined } from "@ant-design/icons";
import {
	Button,
	Divider,
	Empty,
	Image,
	Input,
	Switch,
	Table,
	Tooltip,
} from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CheckboxDropdown from "../components/CheckboxDropdown";
import { getPhasesAndTasks } from "../services/api-server/phases";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import { setLookupData } from "../utils/lookupFinder";
import { HomeContext } from "./Home";
import NoAccess from "./NoAccess";

import dayjs from "dayjs";
import ganttsvg from "../assets/image/chart-gantt.svg";
import elementalSvg from "../assets/image/elemental 'e'.svg";
import GanttChart from "../components/Charts/GanttChart";
import ExpandableText from "../components/ExpandableText";
import AuditLogModal from "../components/Modal/AuditLogModal";
import SearchButton from "../components/SearchButton";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import usePermission from "../hooks/usePermission";
import { getDeliverablesGeneral } from "../services/api-server/deliverables";
import { getOperations } from "../services/api-server/operations";
import { getProject } from "../services/api-server/projects";
import { getWells } from "../services/api-server/wells";
import { excludedMark, statusIcon } from "../utils/statusIcon";
// import {
// 	getDeliverables,
// 	getDeliverablesList,
// } from "../services/api-server/deliverables";
const WellTemplateExample: any = [
	{ label: "Operation title", key: "operation_name" },
	{ label: "Operation type", key: "operation_type" },
	{ label: "Est. duration (days)", key: "estimated_duration_days" },
];

const WellDetailsExample: any = [
	{ label: "Location", key: "location" },
	{ label: "Latitude", key: "latitude" },
	{ label: "Longitude", key: "longitude" },
	{ label: "Project template", key: "project_template" },
	{ label: "Block", key: "block" },
	{ label: "Field", key: "field" },
	{ label: "Platform", key: "platform" },
	{ label: "Water depth", key: "water_depth", suffix: "meters" },
	{ label: "License", key: "license" },
	{ label: "Purpose", key: "well_purpose" },
	{ label: "Status", key: "well_status" },
];

function WellOverview(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [wellInfo, setWellInfo] = useState<any>(null);
	const [wellTitle, setWellTitle] = useState<any>(null);
	const [projectInfo, setProjectInfo] = useState<any>(null);
	const [projectTitle, setProjectTitle] = useState<any>(null);
	const [wellDetailsExpand, setWellDetailsExpand] = useState<any>(false);
	const [activePage, setActivePage] = useState<any>("kanban");
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<any>("");

	const { handleContextMenu } = useHandleContextMenu();

	const hasViewOperationPermission = usePermission(
		"View.Operation",
		projectInfo?.project_code
	);
	const hasEditOperationPermission = usePermission(
		"Edit.Operation",
		projectInfo?.project_code
	);

	const [projectRoleAssignmentList, setProjectRoleAssignmentList] =
		useState<any>([]);

	const [phaseList, setPhaseList] = useState<any>(null);
	const [filteredPhaseList, setFilteredPhaseList] = useState<any>(null);
	const [taskList, setTaskList] = useState<any>(null);
	const [filteredTaskList, setFilteredTaskList] = useState<any>(null);

	const [isLoading, setIsLoading] = useState<any>(true);

	const [selectedFilters, setSelectedFilters] = useState<any>({
		assigned_to: [],
		status: [],
	});

	const [hideExcluded, setHideExcluded] = useState<any>(true);

	//Audit Logs states
	const [auditOpen, setAuditOpen] = useState<any>(false);

	//Gantt data
	const [ganttData, setGanttData] = useState<any>([]);
	const [tableHeight, setTableHeight] = useState<any>(null);
	const [filteredGanttTableData, filterGanttTableData] = useState<any>(null);
	const [searchRecord, setSearchRecord] = useState<any>([]);

	const calculateTableHeight = () => {
		const container: any = document.getElementById("gantt-container");
		if (container) {
			setTableHeight(container?.clientHeight * 0.35 - 120);
		}
	};

	//Setting search record
	const handleSearch = (value: any) => {
		console.log(value);

		let record: any = [...searchRecord];
		let recordIndex = record.findIndex((item: any) => {
			return item.columnKey === value.columnKey;
		});
		if (recordIndex !== -1) {
			if (value.value != "") {
				record[recordIndex] = value;
				setSearchRecord(record);
			} else {
				record.splice(recordIndex, 1);
				setSearchRecord(record);
			}
		} else {
			if (value.value != "") {
				record.push(value);
				setSearchRecord(record);
			}
		}

		//Filter data and table columns based on search record
		let data: any = [...filteredPhaseList];
		console.log(data);
		let filteredData = data
			?.map((element: any) => {
				let bool = record.every((item: any) => {
					if (Array.isArray(element[item.columnKey])) {
						return element[item.columnKey].some((arrayItem: any) =>
							arrayItem.toLowerCase().includes(item.value.toLowerCase())
						);
					} else {
						return element[item.columnKey]
							?.toLowerCase()
							.includes(item.value.toLowerCase());
					}
				});
				if (bool) return element;
			})
			.filter((element: any) => element !== null && element !== undefined);
		console.log(filteredData);
		filterGanttTableData(filteredData);
		data = filteredData;
	};

	const setWellContent = (currentWellData: any, infoKey: any) => {
		let content = "-";
		if (
			currentWellData[infoKey] ||
			infoKey == "latitude" ||
			infoKey == "longitude"
		) {
			content = setLookupData(context.allData, currentWellData, infoKey);
			if (infoKey === "location") {
				content =
					currentWellData[infoKey].charAt(0).toUpperCase() +
						currentWellData[infoKey].slice(1) || "-";
			}
		}
		return content;
	};

	useEffect(() => {
		let filteredTaskList = null;
		if (searchValue != "") {
			let filteredTask: any = {};
			// Object.keys(taskList)?.forEach((taskKeys: any) => {
			// 	console.log(taskKeys);
			// 	filteredTask[taskKeys] = taskList?.[taskKeys]?.filter(
			// 		(task: any) =>
			// 			task?.task_name
			// 				?.toLowerCase()
			// 				.includes(searchValue.toLowerCase()) ||
			// 			task.task_number === searchValue
			// 	);
			// });

			filteredTask = taskList.filter(
				(task: any) =>
					task?.task_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
					task.task_number === searchValue
			);
			filteredTaskList = filteredTask;
		} else {
			filteredTaskList = taskList;
		}

		const check = Object.keys(selectedFilters).some(
			(filterKey: any) => selectedFilters[filterKey].length > 0
		);
		if (check) {
			filteredTaskList = filteredTaskList.filter((task: any) =>
				Object.keys(selectedFilters).every((filterKey: any) => {
					if (selectedFilters[filterKey].length == 0) {
						return true;
					}
					if (typeof task[filterKey] == "object") {
						return task[filterKey].some((key: any) =>
							selectedFilters[filterKey].includes(key)
						);
					} else {
						return selectedFilters[filterKey].includes(task[filterKey]);
					}
				})
			);
		}

		setFilteredTaskList(filteredTaskList);
	}, [searchValue, selectedFilters]);

	useEffect(() => {
		filterToggleData(phaseList);
	}, [hideExcluded]);

	const calculateGanttData = (phaseList: any) => {
		let ganttData = phaseList
			?.filter(
				(phase: any) =>
					phase.planned_start_date && phase.estimated_duration_days
			)
			.map((phase: any, index: any) => {
				let title = phase?.phase_name;
				let startDate = new Date(phase?.planned_start_date);
				let endDate = new Date(
					startDate.getTime() +
						phase?.estimated_duration_days * 24 * 60 * 60 * 1000
				);
				let tooltip = `Title: ${title}<br>Planned start date: ${dayjs(
					startDate
				).format("D MMM YYYY")}<br>Planned end date: ${dayjs(endDate).format(
					"D MMM YYYY"
				)}<br>Estimated duration: ${phase.estimated_duration_days} days`;
				let color = phase.disabled ? "#686868" : "#299BA3";
				return {
					...phase,
					title,
					startDate,
					endDate,
					value: phaseList?.length - index,
					color,
					tooltip,
				};
			});

		setGanttData(ganttData);
	};

	const filterToggleData = (data: any) => {
		if (hideExcluded) {
			const filteredPhaseList = data?.filter((phase: any) => {
				return !phase.disabled;
			});
			setFilteredPhaseList(filteredPhaseList);
			calculateGanttData(filteredPhaseList);
			calculateTableHeight();
		} else {
			setFilteredPhaseList(data);
			calculateGanttData(data);
			calculateTableHeight();
		}
	};

	useEffect(() => {
		window.addEventListener("resize", () => {
			calculateTableHeight();
		});

		if (context.currentTenantKey && context.allData) {
			let project_code: any =
				location?.state?.project_code || location?.pathname?.split("/")[2];
			let operation_code: any =
				location?.state?.operation_code || location?.pathname?.split("/")[3];

			getProject(context.currentTenantKey, project_code)
				.then((project: any) => {
					if (project) {
						setProjectTitle(project.project_name);
						setProjectInfo(project);
						let role1 = [
							{ label: "Project manager", value: "project_manager" },
						];
						let role2 = project.role_assignments.map((role: any) => {
							return { label: role.role, value: role.role_id };
						});
						let roles = role1.concat(role2);
						setProjectRoleAssignmentList(roles);
						context.setCurrentProject(project);
						getOperations(context.currentTenantKey, { operation_code }).then(
							(operationData: any) => {
								getWells(context.currentTenantKey, {
									_id: operationData[0].well_id,
								})
									.then((wellData: any) => {
										const data: any = {
											...operationData[0],
											...wellData[0],
										};
										setWellTitle(data.operation_name);
										setWellInfo(data);
										context.setCurrentOperation(operationData[0]);

										getPhasesAndTasks(context.currentTenantKey, {
											operation_code: data.operation_code,
										}).then((data: any) => {
											setPhaseList(data?.phase_data || []);
											filterToggleData(data?.phase_data || []); // set filteredPhaseList to phaseList
											const taskIDArray = data?.taskList?.map(
												(task: any) => task._id
											);
											getDeliverablesGeneral(
												context.currentTenantKey,
												taskIDArray
											)
												.then((deliverableList: any) => {
													deliverableList?.forEach((deliverable: any) => {
														let responsibleRoles =
															deliverable.responsible_roles || [];
														let accountable_roles =
															deliverable.accountable_roles || [];
														let consulted = deliverable.consulted || [];
														let informed_parties =
															deliverable.informed_parties || [];
														let foundTaskIndex = data?.taskList.findIndex(
															(task: any) => task._id === deliverable.task_id
														);
														if (foundTaskIndex != -1) {
															if (!data.taskList[foundTaskIndex].assigned_to) {
																data.taskList[foundTaskIndex].assigned_to = [];
															}
															data.taskList[foundTaskIndex].assigned_to =
																data.taskList[foundTaskIndex].assigned_to
																	.concat(responsibleRoles)
																	.concat(accountable_roles)
																	.concat(consulted)
																	.concat(informed_parties)
																	.filter(
																		(item: any, index: any, arr: any) =>
																			arr.indexOf(item) === index
																	);
														}
													});
													setIsLoading(false);
													setTaskList(data?.taskList);
													if (localStorage.getItem("selectedFilters")) {
														let selectedFiltersStore: any =
															localStorage.getItem("selectedFilters");
														let selectedFiltersStoreJson =
															JSON.parse(selectedFiltersStore);
														setSelectedFilters(selectedFiltersStoreJson);
														setFilterMode(true);
													}
												})
												.catch((error: any) => {
													console.log(error);
												});
										});
									})
									.catch((error: any) => {
										setWellTitle(null);
										setWellInfo(null);
										setIsLoading(false);
									});
							}
						);
					} else {
						setProjectTitle(null);
						setProjectInfo(null);
						setIsLoading(false);
					}
				})
				.catch((e: any) => {
					setProjectTitle(null);
					setProjectInfo(null);
					setIsLoading(false);
				});
		}
	}, [context.currentTenantKey, context.allData]);

	useMemo(() => {}, [activePage]);

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};
	const handleMenuClick = (field: any, selectedValues: any[]) => {
		// console.log(`Selected: ${field} - ${selectedValues}`);
		// Update the selected filters state
		setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

		localStorage.setItem(
			"selectedFilters",
			JSON.stringify({ ...selectedFilters, [field]: selectedValues })
		);
	};

	const handleClearAllFilters = () => {
		// Reset all filters to empty arrays
		setSelectedFilters({
			assigned_to: [],
			status: [],
		});
		setFilterMode(false);

		// Clear session storage for filter values and checkboxes
		localStorage.removeItem("selectedFilters");

		// Object.keys(selectedFilters).forEach((field) => {
		// 	const storageKey = generateStorageKey(field);
		// 	localStorage.removeItem(storageKey);
		// });

		setTimeout(() => {
			setFilterMode(true);
		}, 5);
	};

	const renderMetaData = () => {
		const numRows = Math.ceil(Object.keys(WellTemplateExample).length / 2);
		const totalColumns = numRows * 2;
		const leftOverColumns = totalColumns - WellTemplateExample.length;

		return (
			<>
				<div
					className="meta-data-container"
					style={{ gridTemplateColumns: "repeat(3, auto)" }}
				>
					{WellTemplateExample.map((element: any, index: any) => (
						<div key={index} className="meta-data-item">
							<span className="panel-detail-label">{element.label}</span>
							<span className="panel-item-content">
								{setWellContent(wellInfo, element.key)}
							</span>
						</div>
					))}
				</div>
				<div className="panel-item-small-container">
					<span className="panel-detail-label">Project comment</span>
					<span className="panel-item-content">
						<ExpandableText
							paragraphText={setWellContent(wellInfo, "well_project_comments")}
							rows={5}
							containerId="main-container"
						/>
					</span>
				</div>
			</>
		);
	};

	const renderDetailData = () => {
		const numRows = Math.ceil(Object.keys(WellDetailsExample).length / 3);
		const totalColumns = numRows * 3;
		const leftOverColumns = totalColumns - WellDetailsExample.length;

		return (
			<div className="extra-panel-container">
				<div
					style={{
						display: "grid",
						columnGap: "20px",
						rowGap: "20px",
						gridTemplateRows: `repeat(4, auto)`,
						gridTemplateColumns: "repeat(3, auto)",
						gridAutoFlow: "column",
						flexGrow: 1,
					}}
				>
					{WellDetailsExample.map((element: any) => (
						<div
							style={{
								display: "grid",
								columnGap: "20px",
								gridTemplateRows: "20px auto",
							}}
						>
							<span className="panel-detail-label">{element.label}</span>
							<span className="panel-item-content">
								{setWellContent(wellInfo, element.key)}
							</span>
						</div>
					))}
					{Array.from({ length: leftOverColumns }, (_, index) => (
						<div
							style={{
								display: "grid",
								rowGap: "10px",
								gridTemplateRows: "auto auto",
							}}
						>
							<span key={index}>{""}</span>
							<span key={index}>{""}</span>
						</div>
					))}
				</div>
				<div className="panel-item-small-container">
					<span className="panel-detail-label">Well comment</span>
					<span className="panel-item-content" style={{ verticalAlign: "top" }}>
						<ExpandableText
							paragraphText={setWellContent(wellInfo, "well_comments")}
							rows={5}
							containerId="main-container"
						/>
					</span>
				</div>
			</div>
		);
	};

	const renderKanbanColumns = () => {
		const renderTasks = (phase: any) => {
			let currentTaskList = filteredTaskList || taskList;
			// check again for excluded tasks if hideExcluded is false
			if (hideExcluded) {
				currentTaskList = currentTaskList.filter((task: any) => !task.disabled);
			}
			let PhasetaskList = currentTaskList.filter(
				(element: any) => element.phase_id == phase._id
			);
			return PhasetaskList?.map((task: any) => {
				const disabled = task.disabled || false;
				const comment = task.disabled_reason || "";
				const tooltipText =
					!disabled || comment == "" ? (
						""
					) : (
						<span>
							This task is excluded from the well programme. You may still click
							to view the details. <br /> <br />
							<b>Comment:</b> <br />
							{comment}
						</span>
					);
				return (
					<div
						className={!disabled ? "task-item" : "task-item task-item-disabled"}
						onClick={() => {
							navigate(`${phase._id}/${task._id}`, {
								replace: false,
								state: { task_id: task._id },
							});
						}}
						onContextMenu={(e: any) => {
							const location = `${window.location.href}/${phase._id}/${task._id}`;
							handleContextMenu(e, { link: location });
						}}
					>
						<Tooltip title={tooltipText}>
							<span style={{ display: "flex", gap: "5px" }}>
								{disabled && excludedMark()}
								{task.task_name}
							</span>
						</Tooltip>
						{statusIcon(task.status)}
					</div>
				);
			});
		};

		return filteredPhaseList?.map((phase: any) => {
			const currentTaskList = filteredTaskList || taskList;
			const disabled = phase.disabled || false;
			const comment = phase.disabled_reason || "";
			const tooltipText = !disabled ? (
				"Click for phase overview"
			) : (
				<span>
					This phase is excluded from the well programme. You may still click
					for phase overview. <br /> <br />
					<b>Comment:</b> <br />
					{comment}
				</span>
			);
			// var percentage = 0;
			// let PhasetaskList = currentTaskList.filter(
			// 	(element: any) => element.phase_id == phase._id
			// );
			// if (PhasetaskList) {
			// const completedTasks = PhasetaskList.filter(
			// 	(task: any) => task.status === "Resolved"
			// );
			// if (completedTasks.length > 0) {
			// 	percentage = Math.floor(
			// 		(completedTasks.length / PhasetaskList.length) * 100
			// 	);
			// }
			// }

			return (
				<div
					style={{
						minWidth: "320px",
						maxWidth: "320px",
						height: "100%",
						display: "flex",
						flexDirection: "column",
						gap: "30px",
					}}
				>
					<div className="phase-progress-bar-bg">
						<span style={{ zIndex: "1" }}>
							{disabled ? "-" : <>{Math.round(phase.progress) || 0}%</>}
						</span>
						{!disabled && (
							<div
								className="phase-progress-bar"
								style={{
									width: `${Math.round(phase.progress) || 0}%`,
								}}
							></div>
						)}
					</div>
					<div className="project-status-container phase-container">
						<div
							className={
								!disabled
									? "project-status-container-header phase-item"
									: "project-status-container-header phase-item phase-item-disabled"
							}
							onClick={() => {
								navigate(`${phase._id}`, {
									replace: false,
									state: { phase_id: phase._id },
								});
							}}
							onContextMenu={(e: any) => {
								const location = `${window.location.href}/${phase._id}`;
								handleContextMenu(e, { link: location });
							}}
						>
							{GetAntIcon("square-check")}
							<Tooltip title={tooltipText}>
								<span style={{ display: "flex", gap: "5px" }}>
									{disabled && excludedMark()}
									{phase.phase_name}
								</span>
							</Tooltip>
						</div>
						<div
							className={
								!disabled
									? "project-status-container-content task-container"
									: "project-status-container-content task-container task-container-disabled"
							}
						>
							{renderTasks(phase)}
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<>
			{hasViewOperationPermission ? (
				<>
					{projectInfo && wellInfo && !isLoading && (
						<div className="well-overview generic-container">
							{/* Top Container */}
							<div className="main-container" id="main-container">
								<div
									className="container-header"
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<div
										className="panel-info"
										onClick={() => {
											setWellDetailsExpand(!wellDetailsExpand);
										}}
									>
										{/* Well Details */}
										<span>Well - operation details</span>
										{wellDetailsExpand
											? GetAntIcon("up")
											: GetAntIcon("downarrow")}
									</div>
									{/* Panel Buttons Container */}
									<div className="panel-main-buttons-container">
										{hasEditOperationPermission && (
											<a
												style={{ marginTop: "auto" }}
												onClick={() => {
													navigate("operation-settings", {
														replace: false,
														state: { ...projectInfo, ...wellInfo },
													});
												}}
												onContextMenu={(e: any) => {
													const location = `${window.location.href}/operation-settings`;
													handleContextMenu(e, { link: location });
												}}
											>
												{GetAntIcon("setting")} Operation settings
											</a>
										)}
										<a
											onClick={() => {
												setAuditOpen(true);
											}}
										>
											{GetAntIcon("history")} Audit log
										</a>
									</div>
								</div>
								{/* Expanded Well Details */}
								{wellDetailsExpand ? (
									<div className="project-details-container">
										<div className="well-project-details-content">
											{renderMetaData()}
										</div>
										{/* Divider */}
										<Divider
											type="vertical"
											style={{ height: "100%", margin: "0px 20px" }}
										></Divider>
										<div style={{ flex: "1" }}>
											<Divider
												orientation="left"
												style={{ fontSize: "14px", marginTop: "0px" }}
											>
												Well details
											</Divider>
											<div>{renderDetailData()}</div>
										</div>
									</div>
								) : (
									<></>
								)}
							</div>
							{/* Bottom Container */}
							<div
								className="main-container"
								style={{
									minHeight: "85%",
									flex: 1,
									width: "100%",
									// maxHeight: "100%",
									overflow: "hidden",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<div className="project-header">
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										<div
											className={
												activePage === "kanban" ? "project-active-button" : ""
											}
											style={{
												display: "flex",
												padding: "4px 15px",
												gap: "8px",
												cursor: "pointer",
											}}
											onClick={() => {
												setActivePage("kanban");
											}}
										>
											{GetAntIcon("project")}
											<span>Kanban</span>
										</div>{" "}
										<div
											className={
												activePage === "ganttChart"
													? "project-active-button"
													: ""
											}
											style={{
												display: "flex",
												padding: "4px 15px",
												gap: "8px",
												cursor: "pointer",
											}}
											onClick={() => {
												setActivePage("ganttChart");
												setTimeout(() => {
													calculateTableHeight();
												}, 1);
											}}
										>
											<Image
												preview={false}
												width={"14px"}
												style={{ marginBottom: "3px" }}
												src={ganttsvg}
												alt="gantt-logo"
											/>
											<span>Gantt chart</span>
										</div>
									</div>
									<div
										style={{
											display: "flex",
											flex: "1",
											justifyContent: "flex-end",
											alignItems: "center",
											gap: "15px",
										}}
									>
										<a
											style={{
												display: activePage === "ganttChart" ? "" : "none",
											}}
											onClick={() => {
												Emitter.emit("RESET-GANTT", null);
											}}
										>
											Reset
										</a>

										<Input
											placeholder="Search by task name"
											className="input-clearable"
											style={{
												maxWidth: "265px",
												display: activePage === "ganttChart" ? "none" : "",
											}}
											onPressEnter={(
												e: React.KeyboardEvent<HTMLInputElement>
											) => {
												setSearchValue(e.currentTarget.value);
												e.currentTarget.blur();
											}}
											onChange={(e) => {
												if (e.target.value === "") {
													setSearchValue("");
												}
											}}
											allowClear
											prefix={<SearchOutlined />}
										/>
										<div style={{ display: "flex", flexDirection: "row" }}>
											{" "}
											<div
												className={
													filterMode || hasSelectedFilters()
														? "project-active-button"
														: ""
												}
												style={{ padding: "4px 10px", cursor: "pointer" }}
												onClick={() => {
													setFilterMode(!filterMode);
												}}
											>
												{GetAntIcon("filter")}
											</div>
											{/* <div
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setDescMode(!descMode);
										}}
									>
										{descMode
											? GetAntIcon("descending")
											: GetAntIcon("ascending")}
									</div> */}
											{/* <div style={{ padding: "4px 10px", cursor: "pointer" }}>
										{GetAntIcon("setting")}
									</div> */}
										</div>
									</div>
								</div>
								<div
									className="generic-content project-overview-container"
									style={{
										flex: 1,
										display: "flex",
										flexWrap: "inherit",
										flexDirection: "column",
										alignItems: "flex-start",
										padding: 0,
										gap: 0,
										overflowX: "auto",
										maxHeight: "100%",
									}}
								>
									<div
										className={`filter-area ${
											filterMode ? "show-filter" : "hide-filter"
										}`}
									>
										{filterMode && (
											<>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														gap: "10px",
														padding: "8px",
														marginRight: "20px",
														alignItems: "center",
														height: "40px",
													}}
												>
													Hide excluded items
													<Switch
														// checkedChildren="Hide excluded items"
														// unCheckedChildren="Show excluded items"
														defaultValue={hideExcluded}
														// style={{ padding: "8px" }}
														onChange={(checked: any) => {
															setHideExcluded(checked);
														}}
													/>
												</div>
												{activePage === "kanban" && (
													<>
														<CheckboxDropdown
															options={projectRoleAssignmentList}
															onChange={(selectedValues) =>
																handleMenuClick("assigned_to", selectedValues)
															}
															onClear={""}
															defaultValue={selectedFilters.assigned_to}
															Key="assigned_to"
															label="Assigned to"
														/>
														<CheckboxDropdown
															options={[
																{ label: "New", value: "New" },
																{
																	label: "To do",
																	value: "To do",
																},
																{ label: "In Progress", value: "In progress" },
																{ label: "Late", value: "Late" },
																{
																	label: "Resolved",
																	value: "Resolved",
																},
															]}
															onChange={(selectedValues) =>
																handleMenuClick("status", selectedValues)
															}
															onClear={""}
															defaultValue={selectedFilters.status}
															Key="status"
															label="Status"
														/>
														<div className="test">
															<Button
																type="link"
																onClick={handleClearAllFilters}
															>
																Reset All
															</Button>
														</div>
													</>
												)}
											</>
										)}
									</div>
									{activePage === "kanban" && (
										<div
											className="kanban-columns-container"
											style={{
												display: "flex",
												flex: 1,
												alignItems: "flex-start",
												padding: "40px 30px",
												gap: "25px",
												alignSelf: "stretch",
												overflow: "auto",
											}}
										>
											{phaseList?.length > 0 ? (
												renderKanbanColumns()
											) : (
												<Empty
													className="no-data-empty"
													style={{ margin: "auto", fontSize: "20px" }}
													image={elementalSvg}
													description="Programme for well is not built yet."
												/>
											)}
										</div>
									)}
									{activePage === "ganttChart" && (
										<div
											id="gantt-container"
											style={{
												display: "flex",
												flexDirection: "column",
												flex: 1,
												width: "100%",
												alignItems: "center",
												padding: "40px 30px",
												gap: "40px",
											}}
										>
											<div style={{ height: "60%", width: "100%" }}>
												<GanttChart data={ganttData} />
											</div>
											<div
												className="table-container"
												style={{
													width: "100%",
													flexGrow: 1,
													padding: "20px 28px",
													background: "rgba(0,0,0,0.15",
												}}
											>
												<Table
													rowClassName={(record: any) => {
														if (record.disabled) {
															return "ganttchart-table-row-disabled";
														} else {
															return "";
														}
													}}
													footer={undefined}
													pagination={false}
													style={{ width: "100%" }}
													className="ganttchart-table"
													dataSource={
														filteredGanttTableData || filteredPhaseList
													}
													scroll={tableHeight && { y: tableHeight, x: 0 }}
													tableLayout="fixed"
													columns={[
														{
															title: (
																<SearchButton
																	handleSearch={handleSearch}
																	headerName="Phase"
																	columnKey="phase_name"
																	key={"phase"}
																/>
															),
															dataIndex: "phase_name",
															render: (value: any, record: any) => (
																<span>
																	{record.disabled && <span>{"(Excl.) "}</span>}
																	{value}
																</span>
															),
															key: "name",
															width: 200,
														},
														{
															title: "Planned start date",
															dataIndex: "planned_start_date",
															key: "planned_start_date",
															render: (value: any) => (
																<span>
																	{value && dayjs(value)?.format("D MMM YYYY")}
																</span>
															),
															sorter: (a: any, b: any) => {
																const stringA = a.planned_start_date || "";
																const stringB = b.planned_start_date || "";
																return stringA.localeCompare(stringB);
															},

															// width: 200,
														},
														{
															title: "Est. duration (days)",
															dataIndex: "estimated_duration_days",
															key: "estimated_duration_days",
															sorter: (a: any, b: any) => {
																const valueA = a.estimated_duration_days
																	? a.estimated_duration_days
																	: -Infinity;
																const valueB = b.estimated_duration_days
																	? b.estimated_duration_days
																	: -Infinity;
																return valueA - valueB;
															},

															// width: 200,
														},
													]}
												></Table>
											</div>
										</div>
									)}
								</div>
							</div>
							<AuditLogModal
								tenantKey={context?.currentTenantKey}
								identifierKeys={{
									project_code: projectInfo?.project_code,
									operation_code: wellInfo?.operation_code,
								}}
								type={"operation"}
								label={"Well operation "}
								open={auditOpen}
								handleClose={() => {
									setAuditOpen(false);
								}}
							/>
						</div>
					)}
					{!projectInfo && !isLoading && (
						<>
							<NoAccess text={"This project does not exist."} />
						</>
					)}
					{!wellInfo && projectInfo && !isLoading && (
						<>
							<NoAccess text={"This well does not exist."} />
						</>
					)}
				</>
			) : (
				<NoAccess
					text={
						"Oops, looks like you don't have the authorisation to view Operations."
					}
				/>
			)}
		</>
	);
}

export default WellOverview;
