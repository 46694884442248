import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import NoAccess from "./containers/NoAccess";
import { sendErrorNotification } from "./services/api-server/base";
import { Image } from "antd";
import elementalSvg from "./assets/image/elemental 'e'.svg";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
const handleErrorFallback = (props: any) => {
	return (
		<div style={{ height: "100vh", width: "100vw" }}>
			<div className="no-access-container">
				<div className="hexlock-container">
					<div className="noaccess-icon">
						<Image
							preview={false}
							width={"185px"}
							className="home-logo"
							src={elementalSvg}
							alt="Logo"
						/>
					</div>
				</div>
				<span
					style={{ fontSize: "20px", lineHeight: "30px", textAlign: "center" }}
				>
					An unexpected error has occurred. Please try again later.
					<br />
					<a href="" style={{ textDecoration: "none" }}>
						Reload Page
					</a>
				</span>
			</div>
		</div>
	);
};

const logError = (error: any, info: any) => {
	if (process.env.NODE_ENV === "production") {
		error.location = window.location.pathname;
		error.level = "root";
		let JSONerror = JSON.stringify(error, Object.getOwnPropertyNames(error));
		sendErrorNotification(JSONerror);
	}
};
root.render(
	<ErrorBoundary fallbackRender={handleErrorFallback} onError={logError}>
		<App />
	</ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
