import { Space, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { HomeContext } from "../../containers/Home";
import useHandleContextMenu from "../../hooks/useHandleContextMenu";
import { GetAntIcon } from "../../utils/ant_icons";
import { setLookupData } from "../../utils/lookupFinder";

const { Text } = Typography;
const statusList = ["New", "In progress", "Resolved"];
const statusCount: any = {
	New: 0,
	"In progress": 30,
	Resolved: 100,
};
const infoKeyList = [
	"project_code",
	"client",
	"region",
	"country",
	"business_unit",
	// "current_phase",
	// "rig_type",
	// "planned_well_duration",
	// "water_depth",
];
const infoIcons: any = {
	project_code: GetAntIcon("number"),
	rig_type: GetAntIcon("project"),
	region: GetAntIcon("compass"),
	country: GetAntIcon("map"),
	business_unit: GetAntIcon("deploymentunit"),
	client: GetAntIcon("thunderbolt"),
	current_phase: GetAntIcon("layout"),
};

function WellCards(props: any) {
	const context: any = useContext(HomeContext);
	const navigate = useNavigate();
	const { handleContextMenu } = useHandleContextMenu();

	const [item, setItem] = useState<any>(props.item);

	const renderStatus = (
		wellProject: any,
		status: string,
		statusType: string
	) => {
		switch (status) {
			case "In progress":
				return (
					<div className="project-status-item status-counter-in-progress">
						<span>In Progress</span>
					</div>
				);

			case "New":
				return (
					<div className="project-status-item status-counter-new">
						<span>New</span>
					</div>
				);

			case "Resolved":
				return (
					<div className="project-status-item status-counter-completed">
						<span>Resolved</span>
					</div>
				);
			default:
				if (status) {
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>
								{setLookupData(context.allData, wellProject, status)}{" "}
								{wellProject?.[statusType] && (
									<>
										({setLookupData(context.allData, wellProject, statusType)})
									</>
								)}
							</span>
						</div>
					);
				} else {
					return <></>;
				}
		}
	};

	const renderPurpose = (
		wellProject: any,
		purpose: string,
		purposeType: string
	) => {
		console.log(wellProject?.[purposeType]);
		switch (purpose) {
			case "In progress":
				return (
					<div className="project-status-item status-counter-in-progress">
						<span>
							{purpose} ({purposeType})
						</span>
					</div>
				);

			case "New":
				return (
					<div className="project-status-item status-counter-new">
						<span>New ({purposeType})</span>
					</div>
				);

			case "Resolved":
				return (
					<div className="project-status-item status-counter-completed">
						<span>Resolved ({purposeType})</span>
					</div>
				);
			default:
				if (purpose) {
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>
								{setLookupData(context.allData, wellProject, purpose)}{" "}
								{wellProject?.[purposeType] && (
									<>
										({setLookupData(context.allData, wellProject, purposeType)})
									</>
								)}
							</span>
						</div>
					);
				} else {
					return <></>;
				}
		}
	};

	const setWellContent = (currentWellData: any, infoKey: any) => {
		let content = "-";
		if (
			currentWellData[infoKey] ||
			infoKey == "latitude" ||
			infoKey == "longitude"
		) {
			content = setLookupData(context.allData, currentWellData, infoKey);
			if (infoKey === "location") {
				content =
					currentWellData[infoKey].charAt(0).toUpperCase() +
						currentWellData[infoKey].slice(1) || "-";
			}
		} else if (infoKey === "actual_start_date") {
			if (currentWellData?.actual_start_date) {
				var date = new Date(currentWellData.actual_start_date);
				// change the date format to "1 Mar 2024"
				content = date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			} else {
				content = "-";
			}
		}
		return content;
	};

	const renderDetail = (item: any) => {
		const showDetailInfo = () => {
			const infoKeyList: any = [
				{ label: "License", key: "license" },
				{ label: "Well operator", key: "well_operator_id" },
				{ label: "Block", key: "block" },
				{ label: "Field", key: "field" },
				{ label: "Water depth", key: "water_depth" },
				{ label: "Location", key: "location" },
				{ label: "Latitude", key: "latitude" },
				{ label: "Longitude", key: "longitude" },
			];

			return (
				<>
					{infoKeyList.map((infoKey: any) => {
						return (
							<Space direction="vertical" size={0}>
								<span style={{ opacity: 0.45, fontSize: "12px" }}>
									{infoKey.label}
								</span>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-start",
										alignItems: "center",
										fontSize: "14px",
										minWidth: "100%",
										width: "0",
									}}
								>
									<Text
										title=""
										style={
											infoKey.key == "country"
												? {
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
												  }
												: {}
										}
										ellipsis={{
											tooltip: <span>{setWellContent(item, infoKey.key)}</span>,
										}}
									>
										<span
											style={
												infoKey.key == "country"
													? {
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
													  }
													: {}
											}
										>
											{setWellContent(item, infoKey.key)}
										</span>
									</Text>
								</div>
							</Space>
						);
					})}
				</>
			);
		};

		return (
			<div>
				<Space direction="vertical" style={{ width: "100%" }} size={15}>
					<Space
						direction="vertical"
						size={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<span
							style={{ opacity: " 0.45", fontSize: "14px", lineHeight: "25px" }}
						>
							Well name
						</span>
						<div style={{ fontSize: "20px", lineHeight: "25px" }}>
							<span
								// className={"project-title-clickable detailed-card-title"}
								onClick={() => {
									navigate(`${item._id}`, {
										replace: false,
										state: {
											...item,
											state: { ...item },
										},
									});
								}}
								onContextMenu={(event: any) => {
									const location = `${window.location.href}/${item._id}`;
									handleContextMenu(event, { link: location });
								}}
							>
								{item.well_name}
							</span>
						</div>
					</Space>
					<div className="project-status">
						{renderPurpose(item, "well_purpose", "well_purpose_type")}
						{renderStatus(item, "well_status", "well_status_type")}
					</div>
					<div className="project-grid-container">{showDetailInfo()}</div>
				</Space>
			</div>
		);
	};

	const renderCompact = (item: any) => {
		return (
			<Space direction="vertical">
				<span style={{ opacity: 0.45, fontSize: "12px", lineHeight: "25px" }}>
					Well name
				</span>
				<Text
					title=""
					style={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
					className={"project-title-clickable"}
					ellipsis={{
						tooltip: <span>{item.well_name}</span>,
					}}
				>
					<span
						onClick={() => {
							navigate(item._id, {
								replace: false,
								state: { ...item },
							});
						}}
						onContextMenu={(event: any) => {
							const location = `${window.location.href}/${item._id}`;
							handleContextMenu(event, { link: location });
						}}
					>
						{item?.well_name}
					</span>
				</Text>
				{/* </Space> */}
				<div className="project-status">
					{renderPurpose(item, "well_purpose", "well_purpose_type")}
					{"  "}
					{renderStatus(item, "well_status", "well_status_type")}
				</div>
			</Space>
		);
	};

	const renderItemDetails = (item: any) => {
		return (
			<Space
				className="project-card-container"
				styles={{ item: { width: "100%" } }}
			>
				{props.activeItems.includes(item["_id"]) ? (
					<>{renderDetail(item)}</>
				) : (
					<>{renderCompact(item)}</>
				)}
			</Space>
		);
	};

	return (
		<div
			className="project-card"
			onClick={() => {
				let currentItems = [...props.activeItems];
				let foundIndex = props.activeItems.findIndex(
					(element: any) => element === item["_id"]
				);

				if (foundIndex < 0) {
					currentItems.push(item["_id"]);
				} else {
					currentItems.splice(foundIndex, 1);
				}
				props.setActiveItems(currentItems);
			}}
		>
			{renderItemDetails(item)}
		</div>
	);
}

export default WellCards;
