import { Popover } from "antd";
import { checkPrivilege } from "../utils/utils";

function LookupMissingTooltip(props: any) {
	return (
		<Popover
			rootClassName="tooltip"
			content={
				<div
					style={{
						padding: "10px 20px",
						maxWidth: "395px",
					}}
				>
					Data unavailable: It may have been deleted. For assistance, please
					contact our support team.
				</div>
			}
			placement="bottomLeft"
		>
			{props.content ? (
				props.content
			) : checkPrivilege(props.userRole, ["tenant-admin"]) ? (
				<span style={{ color: "#FAAD14" }}>Lookup missing</span>
			) : (
				<span style={{ color: "#717171" }}>N/A</span>
			)}
		</Popover>
	);
}

export default LookupMissingTooltip;
