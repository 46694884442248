import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useHandleContextMenu from "../../hooks/useHandleContextMenu";
import usePermission from "../../hooks/usePermission";
import Emitter from "../../utils/emitter";
import WellCards from "./WellCards";

function WellGallery(props: any) {
	const navigate = useNavigate();
	const { handleContextMenu } = useHandleContextMenu();

	const hasCreateWellPermission = usePermission("Create.Well");

	const [allItems, setAllItems] = useState<any[]>(props.allItems);
	const [selectedFiltersProp, setSelectedFiltersProp] = useState<any[]>(
		props.selectedFilters
	);

	useEffect(() => {
		setAllItems(props.allItems);
		console.log("All Items: ", allItems);
	}, [props.allItems]);

	useEffect(() => {
		setSelectedFiltersProp(props.selectedFilters);
		console.log("Selected Filters: ", selectedFiltersProp);
	}, [props.selectedFilters]);

	const renderAllItems = () => {
		let filteredWells =
			props.allItems
				?.filter((well: any) => {
					// Destructure `selectedFilters` from props, which contains filter criteria for wells.
					const { selectedFilters } = props;
					console.log("Destructured `selectedFilters`", selectedFilters);

					// Extract individual filters from `selectedFilters` with default values as empty arrays.
					const {
						location: Location = [],
						well_operator: WellOperator = [],
						well_purpose: WellPurpose = [],
						well_status: WellStatus = [],
					} = selectedFilters || {};

					return (
						// 1. Search filter: If `searchValue` is empty, match all wells.
						// Otherwise, match wells whose names include the search text (case-insensitive).
						(props.searchValue === "" ||
							well.well_name
								.toLowerCase()
								.includes(props.searchValue.toLowerCase())) &&
						// 2. Location filter: If `Location` is empty, match all locations.
						// Otherwise, match wells whose `location` is included in the filter.
						(Location.length === 0 || Location.includes(well.location)) &&
						// 3. Well Operator filter: If `WellOperator` is empty, match all operators.
						// Otherwise, match wells whose `well_operator` matches the filter.
						(WellOperator.length === 0 ||
							WellOperator.includes(well.well_operator)) &&
						// 4. Well Purpose filter: If `WellPurpose` is empty, match all purposes.
						// Otherwise, match wells whose `well_purpose` matches the filter.
						(WellPurpose.length === 0 ||
							WellPurpose.includes(well.well_purpose)) &&
						// 5. Well Status filter: If `WellStatus` is empty, match all statuses.
						// Otherwise, match wells whose `well_status` matches the filter.
						(WellStatus.length === 0 || WellStatus.includes(well.well_status))
					);
				})
				.sort((a: any, b: any) => {
					if (!props.sort) {
						return a.well_name.localeCompare(b.well_name);
					} else {
						return b.well_name.localeCompare(a.well_name);
					}
				}) || [];

		console.log("Filtered Wells:", filteredWells);

		return (
			<>
				{filteredWells.map((well: any) => {
					return (
						<WellCards
							key={well.uuid || well.id || well._id}
							item={well}
							activeItems={props.activeItems}
							setActiveItems={props.setActiveItems}
							sort={props.sort}
						/>
					);
				})}
			</>
		);
	};

	return (
		<div className="gallery">
			<div className="gallery-container">
				{hasCreateWellPermission && (
					<div
						className="add-new-item-div project-card project-add-button"
						onClick={() => {
							Emitter.emit("wellModalOpen", null);
						}}
					>
						+ Add new well
					</div>
				)}
				{renderAllItems()}
			</div>
		</div>
	);
}

export default WellGallery;
