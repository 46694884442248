import chroma from "chroma-js";

function useTheme() {
	const calculateLuminance = (hex: any) => {
		const r = parseInt(hex.slice(1, 3), 16) / 255;
		const g = parseInt(hex.slice(3, 5), 16) / 255;
		const b = parseInt(hex.slice(5, 7), 16) / 255;

		const adjust = (color: any) =>
			color <= 0.03928 ? color / 12.92 : Math.pow((color + 0.055) / 1.055, 2.4);

		const lum = 0.2126 * adjust(r) + 0.7152 * adjust(g) + 0.0722 * adjust(b);
		return lum;
	};

	const darkenColor = (color: string, amount: number) => {
		return chroma(color).darken(amount).hex();
	};

	const lightenColor = (color: string, amount: number) => {
		return chroma(color).brighten(amount).hex();
	};

	const getTextColor = (hex: any) => {
		return calculateLuminance(hex) > 0.5 ? "#000" : "#fff";
	};

	const hexToHSL = (hex: any) => {
		// Convert hex to RGB first
		let r = 0,
			g = 0,
			b = 0;
		if (hex.length === 4) {
			r = parseInt(hex[1] + hex[1], 16);
			g = parseInt(hex[2] + hex[2], 16);
			b = parseInt(hex[3] + hex[3], 16);
		} else if (hex.length === 7) {
			r = parseInt(hex[1] + hex[2], 16);
			g = parseInt(hex[3] + hex[4], 16);
			b = parseInt(hex[5] + hex[6], 16);
		}

		// Convert RGB to HSL
		r /= 255;
		g /= 255;
		b /= 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);

		let h = 0;
		let s,
			l = (max + min) / 2;

		if (max === min) {
			h = s = 0; // achromatic
		} else {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
				default:
					break;
			}
			h /= 6;
		}

		h = Math.round(h * 360);
		s = Math.round(s * 100);
		l = Math.round(l * 100);

		return `hsl(${h}, ${s}%, ${l}%)`;
	};

	const updateThemeProperty = (themeVariables: Record<string, string>) => {
		Object.entries(themeVariables).forEach(([key, value]) => {
			document.documentElement.style.setProperty(`--${key}`, value);
		});
	};

	const resetThemeProperties = () => {
		document.documentElement.style.removeProperty(`style`);

		const varList = [
			"primary-color",
			"secondary-color",
			"default-btn-color",
			"danger-btn-color",
			"primary-text",
			"secondary-text",
			"default-btn-text-color",
			"danger-btn-text-color",
			"progress-bar-color",
			"layout-header-footer-bg",
			"layout-header-footer-text",
			"no-access-background-color",
			"breadcrumb-bg-last",
			"project-cards-bg",
			"project-cards-text-color",
		];
		varList.forEach((variable) => {
			// document.documentElement.style.removeProperty(`--${variable}`);
			document.documentElement.style.setProperty(`--${variable}`, "");
		});
	};

	const setCustomTheme = (theme: any, mode: any) => {
		const theme_properties = {
			"primary-color": theme.primary_color,
			"secondary-color": theme.secondary_color,
			"default-btn-color": theme.default_btn_color,
			"danger-btn-color": theme.danger_btn_color,
			"primary-text": getTextColor(theme.primary_color),
			"secondary-text": getTextColor(theme.secondary_color),
			"default-btn-text-color": getTextColor(theme.default_btn_color),
			"danger-btn-text-color": getTextColor(theme.danger_btn_color),
			"progress-bar-color": `linear-gradient(90deg, ${darkenColor(
				theme.secondary_color,
				0.2
			)} 0%, ${theme.secondary_color} 100%)`,
			"project-cards-bg": '#F8F8F8',
			"project-cards-text-color": '#000000'
		};

		updateThemeProperty(theme_properties);

		if (mode === "dark") {
			const custom_dark_theme_properties = {
				"layout-header-footer-bg": darkenColor(theme.primary_color, 0.2),
				"layout-header-footer-text": getTextColor(
					darkenColor(theme.primary_color, 0.2)
				),
				"no-access-background-color": `linear-gradient(180deg, #000000 3.65%, ${theme.primary_color} 100%)`,
				"breadcrumb-bg-last":
					calculateLuminance(darkenColor(theme.primary_color, 0.2)) > 0.5
						? "rgba(0, 0, 0, 0.1)"
						: "rgba(255, 255, 255, 0.2)",
				"project-cards-bg": darkenColor(theme.primary_color, 0.05),
				"project-cards-text-color": getTextColor(
					darkenColor(theme.primary_color, 0.05)
				),
			};
			updateThemeProperty(custom_dark_theme_properties);
		}
	};

	const setLightDarkMode = (
		mode: string | null,
		defaultMode: boolean | null
	) => {
		if (mode && defaultMode !== null) {
			if (defaultMode === true) {
				document.documentElement.setAttribute("data-theme", "dark-terra");
				// reset documentelement style properties

				if (mode === "light") {
					document.documentElement.setAttribute("data-theme", "light");
				}
				resetThemeProperties();
			} else {
				document.documentElement.setAttribute("data-theme", mode);
			}
		} else {
			document.documentElement.setAttribute("data-theme", "dark-terra");
			resetThemeProperties();
		}
	};

	return {
		calculateLuminance,
		updateThemeProperty,
		getTextColor,
		hexToHSL,
		setLightDarkMode,
		setCustomTheme,
	};
}

export default useTheme;
