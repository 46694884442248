import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "role-permissions";

export const getRolePermissions = (tenantKey: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateRolePermissions = (tenantKey: any, data: any, user: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(`${url}/${tenantKey}/${apiURL}/updateRolePermissions`, { data, user, options: { upsert: true, new: true } }, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};
