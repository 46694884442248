import { Space, Tabs } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const defaultMarkdownFile = require("./WellDeliveredNoContent.md");

const WellDelivered = (props: any) => {
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const fromButtonClick = queryParams.get("fromButtonClick");

	const defaultTabKey = fromButtonClick ? queryParams.get("tab") : props.params.contents[0].key;

	// The current `tabKey` (name of the tab)
	const [tabKey, setTabKey] = React.useState(defaultTabKey);
	// The contents of `props.params.contents`
	const [tab, setTab] = React.useState(props.params.contents[0]);

	const [markdownContent, setMarkdownContent] = React.useState("");

	const loadContent = (key: any) => {
		// Find a matching tab name using `tabKey`(defaultTabKey) in `props.params.contents` 
		const tabContent: any = props.params.contents.find(
			(item: any) => item.key === key
		);

		setTabKey(key); // `defaultTabKey`
		setTab(tabContent); // Tab name found in `props.params.contents`
		
		if (tabContent?.descendants?.length > 0) {
			const descendant = tabContent.descendants[0];
			fetch(descendant.markdownContent)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => console.error("Error fetching Markdown content:", error));
		} else if (tabContent?.markdownContent) {
			fetch(tabContent.markdownContent)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => console.error("Error fetching Markdown content:", error));
		} else {
			fetch(defaultMarkdownFile)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => console.error("Error fetching Markdown content:", error));
		}
	};

	useEffect(() => {
		loadContent(defaultTabKey);
	}, [location]); // Runs everytime `location` changes

	const handleTabChange = (key: any) => {
		loadContent(key);
	};

	useEffect(() => {
		if (queryParams.has("tab")) {
			queryParams.delete("tab");
			console.log("Tab parameter removed", queryParams.toString());
		}
	}, [defaultTabKey]);

	const handleExtraTabChange = async (key: any) => {
		const descendant = tab.descendants.find((item: any) => item.key === key);
		fetch(descendant.markdownContent)
			.then((res) => res.text())
			.then((text) => setMarkdownContent(text))
			.catch((error) => {
				console.error("Error fetching Markdown content:", error);
			});
	};

	// Style the contents of the markdown filesb
	const markdownStyles = {
		Heading: ({ children }: any) => <span className="header">{children}</span>,
		Paragraph: ({ children }: any) => <p className="paragraph">{children}</p>,
		UnorderedList: ({ children }: any) => (
			<ul className="unordered-list">{children}</ul>
		),
		OrderedList: ({ children }: any) => (
			<ol className="ordered-list">{children}</ol>
		),
	};

	return (
		<div
			className="generic-container well-delivered-page"
			style={{ gap: "20px" }}
		>
			<Space direction="vertical">
				<span className="title">{props.params.propTitle}</span>
			</Space>
			<div className="main-container">
				<div className="generic-header">Guides</div>
				<div className="generic-content">
					<Tabs
						style={{ lineHeight: "1.55" }}
						tabPosition="left"
						onChange={handleTabChange} // Ant Design's Tabs component is specifically designed to work with a key for each tab, so it will automatically extract the key of the tabContent the user clicks on
						activeKey={tabKey} // Render the tab content based on the active tab key
						items={props.params.contents}
					/>
					<div className="markdown-content">
						{tab.descendants ? (
							<>
								<Tabs items={tab.descendants} onChange={handleExtraTabChange} />
								<ReactMarkdown
									children={markdownContent}
									components={{
										h1: markdownStyles.Heading,
										p: markdownStyles.Paragraph,
										ul: markdownStyles.UnorderedList,
										ol: markdownStyles.OrderedList,
									}}
									rehypePlugins={[rehypeRaw]}
								/>
							</>
						) : (
							<ReactMarkdown
								children={markdownContent}
								components={{
									h1: markdownStyles.Heading,
									p: markdownStyles.Paragraph,
									ul: markdownStyles.UnorderedList,
									ol: markdownStyles.OrderedList,
								}}
								rehypePlugins={[rehypeRaw]}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WellDelivered;
